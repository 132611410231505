<template>
  <div>
    <TopMenuSubheadline :title="title"/>
    <div class="app-content-box bg-gray">
    <ul class="category-collapse-list wrapper">
    <div v-for="(item, key) in sections" :key="key">
      <li @click="openModal(item.title)">
        <router-link :to="item.path ? item.path : ''">
          <div class="container">
            <a class="category-collapse-link">
              {{item.title}}
            </a>
          </div>
        </router-link>
      </li>
    </div>
    </ul>
    </div>
   <!-- <ModalTemplate v-if="isModalDelete"
    :title="deleteModal.title"  :content="deleteModal.content"
    :acceptButton="deleteModal.buttonFirst" :acceptButtonContent="deleteModal.buttonFirstContent"
    :closeButton="deleteModal.buttonSecond" :closeButtonContent="deleteModal.buttonSecondContent"
    @close-modal="isModalDelete = false"/> -->
      <ModalSlot v-if="isPopupShown" @close-modal="closeModal">
        <div class="text-center mb-2 modal-main-title">Czy na pewno chcesz usunąć konto?</div>
        <div class="w-100"><input type="password" class="input-pass w-100" placeholder="Podaj hasło" v-model="password" /></div>
        <div class="error mt-2">{{errorMessage}}</div>
        <button class="btn-black-full w-100 mt-3 mb-1" @click="deleteAccount">Usuń</button>
        <button class="btn-black-border w-100 mt-2 mb-1" @click="closeModal(false)">Anuluj</button>
    </ModalSlot>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import ModalSlot from '../components/ModalSlot'
export default {
  name: 'Settings',
  components: {
    TopMenuSubheadline,
    ModalSlot
  },
  data () {
    return {
      title: 'Moje konto',
      isModalDelete: false,
      isPopupShown: false,
      password: '',
      errorMessage: '',
      deleteModal: {
        title: 'Czy chcesz usunąć konto?',
        content: 'Wszystkie dane jak m.in. książki, postępy czy konwersacje zostaną nieodwracalnie usunięte',
        buttonFirst: true,
        buttonFirstContent: 'Usuń',
        buttonSecond: true,
        buttonSecondContent: 'Anuluj'
      },
      deleteHistory: {
        title: 'Czy chcesz wyczyścić historię?',
        content: 'Czy na pewno chcesz wyczyścić swoją historię wyszukiwania',
        buttonFirst: true,
        buttonFirstContent: 'Usuń',
        buttonSecond: true,
        buttonSecondContent: 'Anuluj'
      },
      sections: [
        {
          title: 'Edytuj profil',
          path: '/moje-konto/edytuj-profil'
        },
        {
          title: 'Zablokowani użytkownicy',
          path: '/moje-konto/zablokowani'
        }, {
          title: 'Ustawienia powiadomień',
          path: '/moje-konto/ustawienia-powiadomien'
        },
        {
          title: 'Ustawienia prywatności',
          path: '/moje-konto/ustawienia-prywatnosci'
        },
        {
          title: 'Zasady ochrony prywatności',
          path: '/moje-konto/zasady-prywatnosci'
        },
        {
          title: 'Grywalizacja',
          path: '/moje-konto/grywalizacja'
        },
        {
          title: 'Regulamin',
          path: '/moje-konto/regulamin'
        },
        {
          title: 'Pomoc',
          path: '/moje-konto/pomoc'
        },
        {
          title: 'Usuń konto'
        }
      ]
    }
  },
  methods: {
    deleteAccount () {
      const getData = `password=${this.password}`
      this.$https('profile/delete', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        if (response.data.message === 'Hasło nieprawidłowe') {
          this.errorMessage = response.data.message
          this.password = ''
        } else {
          this.$router.push('/logowanie')
          this.deleteToken()
        }
      })
        .catch(error => {
          console.log('', error)
        })
    },
    closeModal () {
      this.isPopupShown = false
    },
    openModal (e) {
      if (e === 'Usuń konto') {
        this.isModalDelete = true
        this.isPopupShown = true
      } else if (e === 'Wyczyść historię wyszukiwania') {
        this.isModalHistory = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.app-content-box {
  min-height: 100vh;
}
  .wrapper {
    padding-top: 30px;
  }
  .category-collapse-link {
    color: #000;
  }
  .input-pass {
    border: none;
    border-bottom: 1px solid #000;

    &::placeholder {
      font-size: 14px;
    }
  }
  .error {
    color: red;
    font-size: 14px;
  }
</style>
